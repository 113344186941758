@import '../../utils/cut-containers';

.generic-hero {
  position: relative;

  &-background {
    background-color: rgba(237, 114, 52, 1);
    width: 100%;
    object-fit: cover;
    z-index: 1;
  }

  &-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $andes-spacing-32;
    position: absolute;
    top: 0;
    width: 100%;
    box-sizing: border-box;
    z-index: 2;
    margin-left: 139px;

    &__message {
      max-width: 650px;

      h1 {
        font-size: 40px !important;
        line-height: 45px !important;
      }
    }
  }

  @media (max-width: 760px) {
    &-content {
      margin: 0px auto;

      h1 {
        font-size: 28px !important;
        line-height: 35px !important;
      }
    }
  }
}
