footer {
  .container {
    background-color: $andes-gray-900-solid;
    padding: $andes-spacing-16 $andes-spacing-64;
    display: flex;
    flex-direction: row;
    text-align: left;
    gap: 10px;

    &__column {
      padding: 8px;
      flex: 20%;
    }

    &__footer-section {
      margin-bottom: $andes-spacing-20;

      &--title {
        margin: $andes-spacing-24 0px !important;
      }

      &--content {

        & a {
          text-decoration: none;
          color: $andes-gray-250-solid;
          transition: color 0.3s ease;
        }

        & a:hover {
          color: $andes-blue-500;
        }

      }

      &--list {
        list-style-type: none;
        padding: 0;

        & li {
          margin-bottom: $andes-spacing-12;
        }
      }

      &__main-logo {
        margin: $andes-spacing-24 0px;
      }
    }

    &__footer-bottom {
      background-color: $guru-gray-070-solid;
      padding: $andes-spacing-16 $andes-spacing-64;
      text-align: left;

      & p {
        margin-bottom: $andes-spacing-16;
      }
    }
  }

  @media (max-width: 768px) {
    .container {
      flex-direction: column;
      padding: $andes-spacing-16 20px;

      &__footer-section {
        &--title {
          margin: $andes-spacing-32 0px !important;
        }
      }

      &__footer-bottom {
        padding: $andes-spacing-16 20px;
      } 
    }
  }
}