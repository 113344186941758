.inactivation-notice {
  padding: 22px;
  &-action{
    color: $andes-blue-500;
  }

  .grid-container__column {
    flex: none;
  }
}
