.calculator-mini {
  flex: 0;
  display: flex;
  flex-direction: column;
  gap: 24px;

  &-title {
    margin: 0;
  }

  &-form {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__action {
      border-radius: 6px !important;
      color: #fff !important;
      font-size: 20px !important;
      margin-top: 10px !important;
      padding: 5px !important;
      width: 40% !important;
    }
  }
}
