$andes-spacing-4: 4px;
$andes-spacing-8: 8px;
$andes-spacing-12: 12px;
$andes-spacing-16: 16px;
$andes-spacing-20: 20px;
$andes-spacing-24: 24px;
$andes-spacing-28: 28px;
$andes-spacing-32: 32px;
$andes-spacing-40: 40px;
$andes-spacing-48: 48px;
$andes-spacing-56: 56px;
$andes-spacing-64: 64px;
$font-weight-light: 300 !default;
$font-weight-regular: 400 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;
$font-family-primary: 'Proxima Nova', -apple-system, 'Roboto', arial, sans-serif !default;
$font-size-base-value: 16;
$font-size-base: 16px;
$font-size-12: 12px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-24: 24px;
$font-size-28: 28px;
$font-size-32: 32px;
$font-size-44: 44px;
