@import '~@andes/accordion/base';

.wave-background {
  background-color: #f9d4c2;
  display: flex;
  justify-content: center;
  clip-path: sine-wave(3, 0.06, 1.2, 8, top);
}

.marker-faq {
  background-color: #ed7234;
  width: 40px;
  height: 8px;
  border-radius: 8px;
  margin: 0px auto;
}
.faq-section {
  border-radius: 12px;
  background-color: white;
  width: 90%;
  margin: 15% auto 30px;
  &__title {
    display: flex;
    justify-content: center;
    font-size: 36px !important;
    padding: 24px;
    margin-top: 10px !important;
  }

  .andes-accordion[data-andes-accordion]
    [data-andes-accordion-header]
    .andes-accordion-header-container {
    padding: 24px;
    border-top: 1px solid #00000040;
  }

  .andes-accordion[data-andes-accordion]
    [data-andes-accordion-header]
    .andes-accordion-header-container__content {
    font-size: 18px;
    font-weight: bold;
  }

  .andes-accordion[data-andes-accordion] [data-andes-accordion-panel] {
    padding: 0px 24px 24px;
    white-space: pre-line;
  }

  .andes-accordion-header-container__indicator > svg > path {
    fill: #ed7234;
  }
}

.accordion-story-container {
  height: auto;
  width: 70%;
  margin: 0px auto;

  &__title {
    padding: 24px;
  }
}

@media (max-width: 768px) {
  .wave-background {
    clip-path: sine-wave(1, 0.06, 1.2, 1, top);
  }

  .faq-section__title {
    font-size: 32px !important;
    padding: 0px;
    margin: 36px auto !important;
  }

  .andes-accordion[data-andes-accordion]
    [data-andes-accordion-header]
    .andes-accordion-header-container {
    padding: 10px 10px 10px !important;
  }
}

@media (max-width: 321px) {
  .faq-section__title {
    font-size: 28px !important;
    padding: 0px;
    margin: 36px auto !important;
    text-align: center;
  }
}
