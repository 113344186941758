@import './loading-display';

.status-display-calculator-mini {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 10px;
  height: 290px;
}

.status-display-calculator-extended {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 10px;
  min-height: 300px;
}
