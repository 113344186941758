.menu-bar {
  &__container {
    position: relative;
  }

  &__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }

  &__item {
    position: relative;
    padding: 10px 0px;
  }

  &__dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    background: #f5f5f5;
    border: 1px solid #ccc;
    box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.1);
    list-style: none;
    padding: 0;
    margin: 0;
    display: block;
    width: 240px;
    border-radius: 6px;

    & li {
      padding: 10px;
    }

    & li:hover a,
    & li:hover a .andes-typography {
      color: #ed7234;
    }

    & a {
      text-decoration: none;
      display: block;
      padding: 10px;
      position: relative;
    }

    & li:hover a::before {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      width: 2px;
      background-color: #ed7234;
      color: #ed7234;
      left: -10px;
    }

    & li.active {
      background-color: #fbe3d6;
    }
  }
}
