/* ---  COLORS  --- */
$guru-white: #fff !default;
$guru-black: #000 !default;
$guru-gray-040-solid: #f5f5f5;
$guru-gray-070-solid: #ededed;
$guru-gray-100-solid: #e5e5e5;
$guru-gray-250-solid: #bfbfbf;
$guru-gray-550-solid: #737373;
$guru-gray-900-solid: #1a1a1a;
$guru-yellow-ml-500: #ffe600;
$guru-orange-100: #fdf0ea;
$guru-orange-150: #fdeae1;
$guru-orange-200: #fbe3d6;
$guru-orange-300: #f9d4c2;
$guru-orange-400: #f8c7ae;
$guru-orange-500: #ed7234;
$guru-orange-600: #e15914;
$guru-orange-700: #c04c11;
$guru-orange-800: #9b3d0e;
$guru-blue-mp-100: #edf5fa;
$guru-blue-mp-150: #e3f0f8;
$guru-blue-mp-200: #daebf6;
$guru-blue-mp-300: #c8e1f1;
$guru-blue-mp-400: #b5d7ed;
$guru-blue-mp-500: #009ee3;
$guru-blue-mp-600: #007eb5;
$guru-blue-mp-700: #005e88;
$guru-blue-mp-800: #004766;
$guru-green-100: #e5f6ed;
$guru-green-150: #d9f2e5;
$guru-green-200: #cceddc;
$guru-green-300: #b2e4ca;
$guru-green-400: #b2e4ca;
$guru-green-500: #00a650;
$guru-green-600: #008744;
$guru-green-700: #063;
$guru-green-800: #004d27;
$guru-red-100: #feeced;
$guru-red-150: #fde2e5;
$guru-red-200: #fcd8dc;
$guru-red-300: #fbc5ca;
$guru-red-400: #fab1b9;
$guru-red-500: #f23d4f;
$guru-red-600: #d12440;
$guru-red-700: #a61d33;
$guru-red-800: #801627;
$guru-text-color-primary: #1a1a1a;
$guru-text-color-secundary: #737373;
$guru-text-color-disabled: #bfbfbf;
$guru-text-color-inverted: #fff;
$guru-text-color-link: #ed7234;
$guru-text-color-negative: #f23d4f;
$guru-text-color-caution: #ffe600;
$guru-text-color-positive: #00a650;
