@import './shared';

$desktop-hero-height: 400px;
$desktop-hero-overlap-height: 100px;

.generic-hero--desktop {

  .generic-hero-background {
    height: $desktop-hero-height + $desktop-hero-overlap-height;
    clip-path: sine-wave(10, 0.04, 1.1, 90);
  }

  .generic-hero-content {
    height: $desktop-hero-height + $desktop-hero-overlap-height;
    padding: $andes-spacing-32;
  }
}
