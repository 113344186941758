.icon-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 296px;
  min-height: 122px;
  max-width: 312px;
  max-height: 122px;
  padding: 24px;
  border-radius: 18px;

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    &--image {
      margin-bottom: 24px;
    }
  }
}