@import '../../styles/style';
@import './index.mobile.scss';
@import './index.tablet.scss';

.calculator-extended {
  display: flex;
  flex-direction: column;
  gap: 20px;

    &-title {
      margin: 0;
    }

    &-form {
      display: flex;
      flex-direction: column;
      gap: 20px;

      &-row {
        display: flex;
        flex-direction: row;
        gap: 8px;
        flex-wrap: wrap;

        &__input {
          flex: auto;

          &--no-wrap {
            min-width: 0;
            width: 1px;
          }
        }
      }
    }

    &__action {
      border-radius: 6px !important;
      padding: 5px !important;
      width: 100% !important;
      margin-top: 10px !important;
      font-size: 20px !important;
    }

    .andes-message--red {
      align-items: center;
      margin-top: 30px;
    }

}

.calculator {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 0 20px;

  &__form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__form-field {
    margin: $andes-spacing-4;
  }

  &__form-input {
    width: 30%;
    flex-basis: 30%;
  }

  .andes-form-control {
    flex: 1;
  }

  &__form-container {
    display: flex;
    gap: $andes-spacing-12;
  }

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }
}



.container-shipping-result {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
