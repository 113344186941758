@media (min-width: 768px) and (max-width: 1000px) {
  .calculator-extended--tablet {
    padding: 0 !important;
    width: 100%;
    margin: 0 auto;
    border-radius: 18px;
    position: relative;
  }

  .result-container {
    margin: 220px 40px 56px !important;
  }

  .calculator {
    border-radius: 18px;
  }

  .landing-screen-mini {
    margin: 220px 30px 100px !important;
  }
}
