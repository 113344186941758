.expandable-section {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 500ms ease-in-out;
  min-height: 60px;
  background-color: #fff;

  &.expandable-section--open {
    grid-template-rows: 1fr;
    background-color: $andes-gray-100-solid;
    min-height: unset;
  }
}

.expandable-section-body {
  overflow: hidden;
}
