.grid-container {
  display: flex;
  justify-content: space-evenly;
  padding: 24px;
  margin: auto;
  max-width: 1144px;

  &__column {
    align-content: center;
    max-width: 576px;
    flex: 0 0 50%;
  }
}