.calculator-extended--mobile {

  .calculator__form-container:first-of-type {
    flex-direction: column;
    gap: 20px;
  }

  .andes-form-control--textfield {
    min-width: 0;
    margin: 0;
  }
}

.calculator-extended__container {
  padding: 24px;
}
