@import '~@andes/card/index';

@import '../calculator-mini';
@import '../calculator-extended';
@import '../status-display';
@import '../expandable-section';

.simulate-section-hero-content {
  max-width: 800px;
  width: 310px;
  min-height: 300px;
  border-radius: 18px !important;
  padding: 32px;
}

.simulate-section-extended {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 800px;
  padding: 0 16px;

  min-height: 300px;
}

.simulate-section-extended-content {
  border-radius: 18px !important;
  height: 100%;
}
