@import '~@andes/button/index';
@import '~@andes/common/lib/styles/visually-hidden';
@import '~@andes/form/base';
@import '~@andes/search-box/index';
@import '~@andes/form/index';
@import '~@andes/button/lib/styles/modifier/dropdownButton';
@import '~@andes/button/lib/styles/modifier/sideDropdownButton';
@import '~@andes/list/base';
@import '~@andes/floating-menu/base';
@import '../../custom-dropdown-menu/index.scss';

body {
  font-family: 'Proxima Nova', -apple-system, Roboto, Arial, sans-serif !important;
  margin: 0;
}

.menu-bar {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 0 2%;
  z-index: 1000;
  height: 48px;
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.1);

  &__icon {
    min-width: 100px;
  }

  &__search-area {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    text-align: right;
    margin-right: 24px;
  }

  &__menu {
    margin-right: 60px;
  }
  .andes-form-control--search-box .andes-form-control__search-icon {
    justify-content: center;
    width: 20px;
    cursor: pointer;
    position: relative;
  }

  .andes-form-control__search-icon svg {
    pointer-events: none;
  }

  &__search {
    width: 250px;
  }

  &__floating-buttom {
    background-color: white !important;
    border: none !important;
    font-size: 16px !important;
    color: #000 !important;
    font-family: 'Proxima Nova', -apple-system, Roboto, Arial, sans-serif;
    font-weight: 400;
    padding: 10px 0px !important;

    &--p0 {
      padding: 0;
    }

    span > svg {
      display: none;
    }

    .andes-button .andes-button__content svg {
      display: none !important;
    }

    .andes-list.andes-floating-menu.andes-list--default.andes-list--selectable
      .andes-list__item:active {
      background-color: #fbe3d6;
    }

    .andes-list.andes-floating-menu.andes-list--default.andes-list--selectable
      .andes-list__item {
      position: relative;
    }

    .andes-list.andes-floating-menu.andes-list--default.andes-list--selectable
      .andes-list__item:hover::before {
      content: '';
      position: absolute;
      top: 10%;
      bottom: 10%;
      left: 0;
      width: 2px;
      background-color: #ed7234;
    }

    .andes-list.andes-floating-menu.andes-list--default.andes-list--selectable
      .andes-list__item:hover
      .andes-typography {
      color: #ed7234;
    }
  }
  &__floating-buttom:hover {
    color: #000;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
    gap: 24px;

    button:last-child {
      margin-left: -16px;
    }

    a {
      display: inline-flex;
      width: auto;
      text-decoration: none;
    }
  }

  &__img {
    height: 30px;
    margin-top: 5px;
  }

  .andes-list__item--size-medium .andes-list__item-primary,
  .andes-list__item--size-medium .andes-list__item-tertiary {
    font-size: 12px;
  }

  .andes-list__item {
    padding: 0;
  }

  &__item {
    margin-right: 20px;
  }

  &__item:last-child {
    margin-right: 0;
  }

  &__button {
    &--second {
      font-size: $font-size-14 !important;
      font-weight: 600 !important;
    }
  }

  &__icon {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-right: 0;
  }

  @media screen and (min-width: 900px) and (max-width: 1130px) {
    &__search {
      width: auto;
    }
  }
}

.m-left-16 {
  margin-left: 16px;
}
