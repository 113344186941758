@import '~@andes/message/index';
@import '~@andes/badge/base';
@import '~@andes/badge/lib/styles/modifier/notification';
@import '~@andes/badge/lib/styles/size/small';
@import '~@andes/badge/lib/styles/color/red';
@import '~@andes/list/index';
@import '~@andes/dropdown/index';
@import '~@andes/floating-menu/base';

.results-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: transparent !important;

  .andes-list__item {
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, .1);
  }

  .andes-list__item:hover {
    background-color: $guru-orange-100 !important;
  }

  &-footer {
    margin: 0 20px;
  }

  &-content {
    display: flex;
    align-items: center;

    &-item {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding: $andes-spacing-16;

      &__image {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        gap: 0;
        flex-basis: 50px;
        flex-grow: 0;
        margin-left: 20px;
        & img {
          border-radius: 6px;
        }
      }

      &__price {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-evenly;
        margin-left: 10px;
        margin-right: 10px;
        gap: 0;
        flex-basis: 100px;
        flex-grow: 1;
        text-align: left;
      }

      &__delivery-time {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        gap: 12px;
      }

      &__span {
        align-items: center;
        display: flex;
        flex-basis: 100px;
        flex-flow: row nowrap;
        flex-grow: 1;
        gap: 0;
        justify-content: space-between;
        text-align: left;
      }
    }
  }

}

.results-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: $andes-spacing-40;

  .andes-dropdown.andes-dropdown--form {
    padding-top: 0;
  }

  .andes-dropdown.andes-dropdown--form .andes-dropdown__trigger span {
    color: #ed7234;
  }

  .andes-dropdown.andes-dropdown--form .andes-dropdown__trigger {
    font-size: 14px;
    background-color: #fdeae1;
    margin: 0;
    max-height: 0;
    box-shadow: none;
    min-height: 32px;
  }

  .andes-list__item--size-medium .andes-list__item-primary,
  .andes-list__item--size-medium .andes-list__item-tertiary {
    font-size: 14px;
    line-height: 16px;
  }

  .andes-dropdown.andes-dropdown--form
  .andes-dropdown__trigger:focus:not(:focus-visible) {
    box-shadow: none;
  }
}
