@import '../../styles/style';
@import '../../components/header/desktop';
@import '../../components/footer';
@import '../../components/generic-hero/desktop';
@import '../../components/icon-box';

@import '../../components/simulate-section/desktop';
@import '../../components/grid-container/desktop';
@import '../../components/inactivation-notice';
@import '../../components/frequently-asked-questions/index.scss';

body {
  margin: 0;
}

.expandable-section-body__content {
  padding: 0 $andes-spacing-64 0 $andes-spacing-64;
}
